import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppProvider } from './context/app';
import { UploadContextProvider } from './context/uploadContext';
import App, { ErrorBoundary } from './pages/App';
import { LOCAL_STORAGE_DEVICE_ID, LOCAL_STORAGE_USER } from './common/constants';
import { uuidv4 } from './common/uuid';

declare global {
  interface Window {
    Appcues: any;
  }
}
declare global {
  interface Window {
    AppcuesSettings: any;
  }
}
declare global {
  interface Window {
    dataLayer: any;
  }
}
let uuid = localStorage.getItem(LOCAL_STORAGE_DEVICE_ID);
//const userInfos: { email: string } = { email: '' };
if (!uuid) {
  uuid = uuidv4();
  //const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
  //if (user) {
  //  uuid = user.id;
  //  userInfos.email = user.email;
  //} else {
  //  uuid = uuidv4();
  //}
  localStorage.setItem(LOCAL_STORAGE_DEVICE_ID, uuid);
}
console.log('Identifying appcues with uuid: ' + uuid);

// App-Cues setup
if (window.Appcues !== null && window.Appcues !== undefined) {
  let uuid = localStorage.getItem(LOCAL_STORAGE_DEVICE_ID);
  const userInfos: { email: string } = { email: null };
  if (!uuid) {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
    if (user) {
      uuid = user.id;
      userInfos.email = user.email;
    } else {
      uuid = uuidv4();
    }
    localStorage.setItem(LOCAL_STORAGE_DEVICE_ID, uuid);
  }
  window.Appcues.identify(uuid, userInfos);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <AppProvider>
          <UploadContextProvider>
            <App />
          </UploadContextProvider>
        </AppProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  </BrowserRouter>
);
